import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import { Link } from "gatsby"
  ;

import { LocalBar, HelpOutline, AirplanemodeActive, SupervisorAccount, LocalDining } from '@material-ui/icons';

const lcolor = "#00295D"

const timeline = [
  {
    title: `Openingsfeest: Tofflords`,
    date: `16 Januari - Toffler`,
    path: `/feest`,
    icon: <LocalBar />,
    content: `Met het openingsfeest Tofflords zal de spits afgebeten worden van wat belooft een mooi lustrumjaar te worden.`
  },
  {
    title: `Pubquiz`,
    date: `Februari - TBA`,
    path: `/pubquiz`,
    icon: <HelpOutline />,
    content: `In Februari zullen we een pubquiz houden waarbij deze belangrijke deugd van de lords aan de kaak wordt gesteld.`
  },
  {
    title: `Gala`,
    date: `14 Maart - Kasteel De Berckt`,
    path: `/gala`,
    icon: <LocalDining />,
    content: `Dit Lustrum zullen we ons op 14 maart 2020 met alle Lords ons verplaatsten naar Kasteel de Berckt te Baarlo.`
  },
  {
    title: `Lustrumreis`,
    date: `28 mei tot 1 juni - Scottish Highlands`,
    path: `/reis`,
    icon: <AirplanemodeActive />,
    content: `Op pelgrimstocht naar William Wallace.`
  },
  {
    title: `Vader-zoon dag`,
    date: `Juni - TBA`,
    path: `/vazo`,
    icon: <SupervisorAccount />,
    content: `TBA`
  }
]

const useStyles = makeStyles(theme => ({
  timelinebottom: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    "& a": {
      textDecoration: "none"
    }
  }
}))

const IndexPage = () => {
  const classes = useStyles()
  return <Layout>
    <SEO title="Home" />
    <VerticalTimeline>
      {timeline.map(i =>
        <VerticalTimelineElement
          key={i.title}
          className="vertical-timeline-element--work"
          contentStyle={{ background: lcolor, color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: lcolor, color: '#fff' }}
          icon={i.icon}
        >
          <h3 className="vertical-timeline-element-title">{i.title}</h3>
          <h4 className="vertical-timeline-element-subtitle">{i.date}</h4>
          <p >
            {i.content}
          </p>
          <div className={classes.timelinebottom}>
            <Link
              to={i.path}>
              <Button variant="contained">Meer</Button>
            </Link>
          </div>
        </VerticalTimelineElement>)}
    </VerticalTimeline>
  </Layout>
}
export default IndexPage
